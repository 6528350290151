import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12020840"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-container" }
const _hoisted_2 = {
  "data-testid": "accordion-title-slot-content",
  class: "mb-0"
}
const _hoisted_3 = {
  class: "accordion-body",
  "data-testid": "accordion-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "accordion-header",
      "data-testid": "accordion-header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["icon text-primary", {'icon-chevron-down':_ctx.isOpen, 'icon-chevron-up':!_ctx.isOpen}]),
        "data-testid": "accordion-chevron"
      }, null, 2),
      _createTextVNode(),
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.titleKey ? _ctx.t(_ctx.titleKey) : _ctx.title), 1)
      ], true)
    ]),
    _createTextVNode(),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ]))
}
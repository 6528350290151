import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_NameNsDescription = _resolveComponent("NameNsDescription")!
  const _component_PodSecurityAdmission = _resolveComponent("PodSecurityAdmission")!
  const _component_CruResource = _resolveComponent("CruResource")!

  return (_ctx.$fetchState.pending)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_CruResource, {
        key: 1,
        mode: _ctx.mode,
        resource: _ctx.value,
        errors: _ctx.errors,
        "cancel-event": true,
        "done-route": _ctx.doneRoute,
        onError: _ctx.setErrors,
        onFinish: _ctx.save,
        onCancel: _ctx.done
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NameNsDescription, {
            value: _ctx.value,
            namespaced: false,
            mode: _ctx.mode,
            "description-key": "description"
          }, null, 8, ["value", "mode"]),
          _createTextVNode(),
          _createVNode(_component_PodSecurityAdmission, {
            labels: _ctx.defaults,
            "labels-always-active": true,
            exemptions: _ctx.exemptions,
            mode: _ctx.mode,
            onUpdateLabels: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDefaults($event))),
            onUpdateExemptions: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setExemptions($event)))
          }, null, 8, ["labels", "exemptions", "mode"])
        ]),
        _: 1
      }, 8, ["mode", "resource", "errors", "done-route", "onError", "onFinish", "onCancel"]))
}
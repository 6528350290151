import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(['badge-state', _ctx.bg])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["icon", {[_ctx.icon]: true, 'mr-5': !!_ctx.msg}])
        }, null, 2))
      : _createCommentVNode("", true),
    _createTextVNode(_toDisplayString(_ctx.msg), 1)
  ], 2))
}
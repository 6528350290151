import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "data-testid", "disabled", "placeholder", "spellcheck"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", _mergeProps({
    ref: "ta",
    value: _ctx.value,
    "data-testid": _ctx.$attrs['data-testid'] ? _ctx.$attrs['data-testid'] : 'text-area-auto-grow',
    disabled: _ctx.isDisabled,
    style: _ctx.style,
    placeholder: _ctx.placeholder,
    class: [_ctx.className, "no-resize no-ease"]
  }, _ctx.$attrs, {
    spellcheck: _ctx.spellcheck,
    onPaste: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('paste', $event))),
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event))),
    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus', $event))),
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur', $event)))
  }), null, 16, _hoisted_1))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-error",
  "data-testid": "error-span"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!

  return (!!_ctx.asBanner && !!_ctx.validationMessage)
    ? (_openBlock(), _createBlock(_component_Banner, {
        key: 0,
        color: "error",
        label: _ctx.validationMessage,
        icon: _ctx.icon
      }, null, 8, ["label", "icon"]))
    : (!!_ctx.validationMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.validationMessage), 1))
      : _createCommentVNode("", true)
}
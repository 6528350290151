import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["id", "disabled", "name", "value", "checked"]
const _hoisted_2 = ["tabindex", "aria-label", "aria-checked"]
const _hoisted_3 = { class: "labeling" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "radio-button-outer-container-description"
}
const _hoisted_7 = {
  key: 1,
  class: "radio-button-outer-container-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass({'disabled': _ctx.isDisabled, 'radio-container': true}),
    onKeydown: [
      _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.clicked($event)), ["enter"])),
      _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.clicked($event)), ["space"]))
    ],
    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.clicked($event)), ["stop"]))
  }, [
    _createElementVNode("input", {
      id: _ctx.randomString,
      disabled: _ctx.isDisabled,
      name: _ctx.name,
      value: ''+_ctx.val,
      checked: _ctx.isChecked,
      type: "radio",
      tabindex: -1,
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"]))
    }, null, 8, _hoisted_1),
    _createTextVNode(),
    _createElementVNode("span", {
      ref: "custom",
      class: _normalizeClass([ _ctx.isDisabled ? 'text-muted' : '', 'radio-custom']),
      tabindex: _ctx.isDisabled ? -1 : 0,
      "aria-label": _ctx.label,
      "aria-checked": _ctx.isChecked,
      role: "radio"
    }, null, 10, _hoisted_2),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", {
        class: _normalizeClass([ _ctx.muteLabel ? 'text-muted' : '', 'radio-label', 'm-0']),
        for: _ctx.name
      }, [
        (_ctx.hasLabelSlot)
          ? _renderSlot(_ctx.$slots, "label", { key: 0 })
          : (_ctx.label)
            ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, null, 512)), [
                [_directive_clean_html, _ctx.label]
              ])
            : _createCommentVNode("", true)
      ], 10, _hoisted_4),
      _createTextVNode(),
      (_ctx.descriptionKey || _ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.descriptionKey)
              ? (_openBlock(), _createBlock(_component_t, {
                  key: 0,
                  k: _ctx.descriptionKey
                }, null, 8, ["k"]))
              : (_ctx.description)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.description), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ]))
        : (_ctx.hasDescriptionSlot)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "description")
            ]))
          : _createCommentVNode("", true)
    ])
  ], 34))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c4a7406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 2,
  class: "required"
}
const _hoisted_3 = ["maxlength", "disabled", "type", "value", "placeholder", "data-lpignore"]
const _hoisted_4 = {
  key: 2,
  class: "sub-label"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _component_TextAreaAutoGrow = _resolveComponent("TextAreaAutoGrow")!
  const _component_LabeledTooltip = _resolveComponent("LabeledTooltip")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'labeled-input': true,
      focused: _ctx.focused,
      [_ctx.mode]: true,
      disabled: _ctx.isDisabled,
      [_ctx.status]: _ctx.status,
      suffix: _ctx.hasSuffix,
      'v-popper--has-tooltip': _ctx.hasTooltip,
      'compact-input': _ctx.isCompact,
      hideArrows: _ctx.hideArrows,
      [_ctx.className]: true
    })
  }, [
    _renderSlot(_ctx.$slots, "label", {}, () => [
      (_ctx.hasLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
            (_ctx.labelKey)
              ? (_openBlock(), _createBlock(_component_t, {
                  key: 0,
                  k: _ctx.labelKey
                }, null, 8, ["k"]))
              : (_ctx.label)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                  ], 64))
                : _createCommentVNode("", true),
            _createTextVNode(),
            (_ctx.requiredField)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], true),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "prefix", {}, undefined, true),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "field", {}, () => [
      (_ctx.type === 'multiline' || _ctx.type === 'multiline-password')
        ? (_openBlock(), _createBlock(_component_TextAreaAutoGrow, _mergeProps({
            key: 0,
            ref: "value"
          }, _ctx.$attrs, {
            maxlength: _ctx._maxlength,
            disabled: _ctx.isDisabled,
            value: _ctx.value || '',
            placeholder: _ctx._placeholder,
            autocapitalize: "off",
            class: { conceal: _ctx.type === 'multiline-password' },
            "onUpdate:value": _ctx.onInput,
            onFocus: _ctx.onFocus,
            onBlur: _ctx.onBlur
          }), null, 16, ["maxlength", "disabled", "value", "placeholder", "class", "onUpdate:value", "onFocus", "onBlur"]))
        : (_openBlock(), _createElementBlock("input", _mergeProps({
            key: 1,
            ref: "value",
            class: { 'no-label': !_ctx.hasLabel }
          }, _ctx.$attrs, {
            maxlength: _ctx._maxlength,
            disabled: _ctx.isDisabled,
            type: _ctx.type === 'cron' ? 'text' : _ctx.type,
            value: _ctx.value,
            placeholder: _ctx._placeholder,
            autocomplete: "off",
            autocapitalize: "off",
            "data-lpignore": _ctx.ignorePasswordManagers,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }), null, 16, _hoisted_3))
    ], true),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "suffix", {}, undefined, true),
    _createTextVNode(),
    (_ctx.hasTooltip && !_ctx.focused)
      ? (_openBlock(), _createBlock(_component_LabeledTooltip, {
          key: 0,
          hover: _ctx.hoverTooltip,
          value: _ctx.tooltipValue,
          status: _ctx.status
        }, null, 8, ["hover", "value", "status"]))
      : _createCommentVNode("", true),
    _createTextVNode(),
    (!!_ctx.validationMessage)
      ? (_openBlock(), _createBlock(_component_LabeledTooltip, {
          key: 1,
          hover: _ctx.hoverTooltip,
          value: _ctx.validationMessage
        }, null, 8, ["hover", "value"]))
      : _createCommentVNode("", true),
    _createTextVNode(),
    (_ctx.cronHint || _ctx.subLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.cronHint)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.cronHint), 1))
            : _createCommentVNode("", true),
          _createTextVNode(),
          (_ctx.subLabel)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, null, 512)), [
                [_directive_clean_html, _ctx.subLabel]
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
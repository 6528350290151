import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-modal"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createVNode(_Transition, {
      name: "modal-fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "modal-overlay",
          "data-modal": _ctx.name,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
        }, [
          _createElementVNode("div", _mergeProps(_ctx.$attrs, {
            ref: "modalRef",
            class: [_ctx.customClass, "modal-container"],
            style: _ctx.modalStyles,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }), [
            _renderSlot(_ctx.$slots, "default")
          ], 16)
        ], 8, _hoisted_1)
      ]),
      _: 3
    })
  ]))
}
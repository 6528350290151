import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-574aed92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "feature-checkboxes col span-6" }
const _hoisted_3 = { class: "col span-6" }
const _hoisted_4 = { class: "row mb-10" }
const _hoisted_5 = { class: "col span-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Banner, {
      color: "info",
      "label-key": "gke.loggingService.infoBanner"
    }),
    _createTextVNode(),
    (_ctx.showLoggingMonitoringBanner)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          color: "warning",
          "label-key": "gke.loggingService.warningBanner"
        }))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Checkbox, {
          value: _ctx.loggingEnabled,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loggingEnabled) = $event)),
          mode: _ctx.mode,
          "label-key": "gke.loggingService.label"
        }, null, 8, ["value", "mode"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.monitoringEnabled,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.monitoringEnabled) = $event)),
          mode: _ctx.mode,
          "label-key": "gke.monitoringService.label"
        }, null, 8, ["value", "mode"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.httpLoadBalancing,
          mode: _ctx.mode,
          "label-key": "gke.httpLoadBalancing.label",
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:httpLoadBalancing', $event)))
        }, null, 8, ["value", "mode"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.horizontalPodAutoscaling,
          mode: _ctx.mode,
          "label-key": "gke.horizontalPodAutoscaling.label",
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:horizontalPodAutoscaling', $event)))
        }, null, 8, ["value", "mode"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.enableKubernetesAlpha,
          mode: _ctx.mode,
          "label-key": "gke.enableKubernetesAlpha.label",
          disabled: !_ctx.isNewOrUnprovisioned,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:enableKubernetesAlpha', $event)))
        }, null, 8, ["value", "mode", "disabled"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LabeledSelect, {
          mode: _ctx.mode,
          options: _ctx.maintenanceWindowOpts,
          "label-key": "gke.maintenanceWindow.label",
          value: _ctx.maintenanceWindow,
          onSelecting: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:maintenanceWindow', $event.value)))
        }, null, 8, ["mode", "options", "value"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.enableKubernetesAlpha)
          ? (_openBlock(), _createBlock(_component_Banner, {
              key: 0,
              color: "warning",
              "label-key": "gke.enableKubernetesAlpha.warning",
              icon: "icon-warning"
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "taint" }
const _hoisted_2 = { style: {'width': '40%'} }
const _hoisted_3 = { style: {'width': '40%'} }
const _hoisted_4 = { style: {'width': '15%'} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_LabeledInput, {
        value: _ctx.key,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.key) = $event)),
        mode: _ctx.mode,
        rules: [()=>_ctx.validationMessage],
        type: "text",
        "data-testid": "aks-taint-key-input"
      }, null, 8, ["value", "mode", "rules"])
    ]),
    _createTextVNode(),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(_component_LabeledInput, {
        value: _ctx.value,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
        mode: _ctx.mode,
        type: "text",
        rules: [()=>_ctx.validationMessage],
        "data-testid": "aks-taint-value-input"
      }, null, 8, ["value", "mode", "rules"])
    ]),
    _createTextVNode(),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(_component_Select, {
        value: _ctx.effect,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.effect) = $event)),
        mode: _ctx.mode,
        options: _ctx.effectOptions,
        label: "effect",
        "data-testid": "aks-taint-effect-select"
      }, null, 8, ["value", "mode", "options"])
    ]),
    _createTextVNode(),
    _createElementVNode("td", null, [
      (_ctx.isEdit)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn role-link btn-sm",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('remove')))
          }, _toDisplayString(_ctx.t('generic.remove')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
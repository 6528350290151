import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64a42254"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "type", "disabled", "tab-index", "data-testid"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("button", {
    ref: "btn",
    class: _normalizeClass(_ctx.classes),
    name: _ctx.name,
    type: _ctx.type,
    disabled: _ctx.isDisabled,
    "tab-index": _ctx.tabIndex,
    "data-testid": _ctx.componentTestid + '-async-button',
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
  }, [
    (_ctx.mode === 'manual-refresh')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t('action.refresh')), 1))
      : _createCommentVNode("", true),
    _createTextVNode(),
    (_ctx.displayIcon)
      ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass({icon: true, 'icon-lg': true, [_ctx.displayIcon]: true})
        }, null, 2)), [
          [_directive_clean_tooltip, _ctx.tooltip]
        ])
      : _createCommentVNode("", true),
    _createTextVNode(),
    (_ctx.labelAs === 'text' && _ctx.displayLabel)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
          [_directive_clean_tooltip, _ctx.tooltip],
          [_directive_clean_html, _ctx.displayLabel]
        ])
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
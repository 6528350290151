import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Banner, {
      label: _ctx.t('authConfig.stateBanner.disabled', _ctx.tArgs),
      color: "warning",
      "data-testid": "auth-provider-disabled-warning-banner"
    }, null, 8, ["label"]),
    _createTextVNode(),
    _createVNode(_component_Banner, {
      color: "warning",
      "data-testid": "auth-provider-admin-permissions-warning-banner"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_directive_clean_html, _ctx.t('authConfig.associatedWarning', _ctx.tArgs, true)]
        ])
      ]),
      _: 1
    })
  ]))
}